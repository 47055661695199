export default class EventStatisticSummaryModel {
  constructor(){
    this.emailCount = null;
    this.phoneCount = null;
    this.totalCount = null;
  }
  setData(obj){
    const { emailCount, phoneCount } = obj;
    this.emailCount = emailCount;
    this.phoneCount = phoneCount;
    this.totalCount = emailCount + phoneCount;
  }
}