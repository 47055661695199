import { convertDateFormat, dateDiff, calcDate, getweekDay, getDayToWeek, getWeeklyDateList, getMonthlyDateList, getMonthLastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import { GET_REQUEST, POST_REQUEST, PUT_REQUEST, DELETE_REQUEST } from '@/services/http';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import EventStatisticSummaryModel from '@/views/landing/statistic/model/EventStatisticSummaryModel'
import EventStatisticWeeklyModel from '@/views/landing/statistic/model/EventStatisticWeeklyModel'
import EventStatisticMonthlyModel from '@/views/landing/statistic/model/EventStatisticMonthlyModel'
import EventStatisticListModel from '@/views/landing/statistic/model/EventStatisticListModel'
import ScheduleModel from '@/views/dashboard/dashboard/model/ScheduleModel'
import ScheduleItemModel from '@/views/dashboard/dashboard/model/ScheduleItemModel'

export default class EventStatisticViewModel {
  constructor() {
    this.responseAt = ''; // Api 조회 시간

    // summary
    this.summaryModel = new EventStatisticSummaryModel();

    // weekly
    this.weeklyModel = new EventStatisticWeeklyModel();

    // monthly
    this.monthlyModel = new EventStatisticMonthlyModel();
    this.scheduleModel = new ScheduleModel();
    this.scheduleItemModel = new ScheduleItemModel();
    this.isSchedulePopup = false;
    this.isSchedulePopupModify = false;

    // list
    this.boardData = {
      title:'신청상세목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'등록일시',
          width:'20%',
          value:'createdAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isEllip: true,
        },
        {
          title:'이메일주소',
          width:'35%',
          value:'email',
          isEllip: true,
        },
        {
          title:'휴대폰 번호',
          width:'35%',
          value:'phoneNumber',
          isEllip: true,
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.listModel = new EventStatisticListModel();
  }

  init(){
    // summary
    this.getSummaryData();

    // weekly
    this.weeklyModel.setTargetDate(new Date());
    this.getWeeklyDate();

    // monthly
    this.monthlyModel.setMonthDate();
    // this.getMonthData();
    // this.getScheduleData();

    // info
    this.getInfoData();
  }
  onClickPrevDate(){
    const prevDate = calcDate(this.weeklyModel.targetDate,-7,'day');
    this.weeklyModel.setTargetDate(prevDate);
    this.getWeeklyDate();
  }
  onClickNextDate(){
    const nextDate = calcDate(this.weeklyModel.targetDate,7,'day');
    this.weeklyModel.setTargetDate(nextDate);
    this.getWeeklyDate();
  }
  getDataCalendar(){
    this.getMonthData();
    this.getScheduleData();
  }
  onAddSchedule(date){
    this.scheduleItemModel.setAddScheduleData(date);
    this.isSchedulePopupModify = false;
    this.isSchedulePopup = true;
  }
  onClickCloseSchedulePopup(){
    this.onCloseSchedulePopup();
  }
  onClickCompleteSchedulePopup(){
    if(this.isSchedulePopupModify){
      this.putScheduleData()
    }else{
      this.postScheduleData()
    }
  }
  onCloseSchedulePopup(){
    this.isSchedulePopup = false;
    this.scheduleItemModel = new ScheduleItemModel();
  }
  onClickDeleteSchedule(scheduleData){
    const requestDelete = () => {
      this.deleteScheduleData(scheduleData.id);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `스케줄을 정말 삭제하시겠습니까?`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickModifySchedule(scheduleData){
    this.scheduleItemModel.setModifyScheduleData(scheduleData);
    this.isSchedulePopupModify = true;
    this.isSchedulePopup = true;
  }
  // daily
  onSearch(){
    this.getInfoData();
  }


 // API
  getSummaryData(){
    const path = `${apiPath.SURVEY_SUMMARY}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.summaryModel.setData(resultData);
      this.responseAt = new Date();
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getWeeklyDate(){
    const query = makeQueryStringByObject(this.weeklyModel.searchDateRangeWeekly);
    const path = `${apiPath.SURVEY_DAILY}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.weeklyModel.setData(resultData.data)
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }

  getMonthData(){
    this.monthlyModel.monthDataList = [];
    const query = makeQueryStringByObject(this.monthlyModel.calendarData.searchDataRangeMonthly);
    const path = `${apiPath.SURVEY_DAILY}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.monthlyModel.monthDataList = resultData.data;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 스케쥴 상세
  getScheduleData(){
    const { start_date, end_date } = this.monthlyModel.calendarData.searchDataRangeMonthly;
    const searchDate = {
      startDate : start_date,
      endDate : end_date,
    }
    const query = makeQueryStringByObject(searchDate);
    const path = `${apiPath.SCHEDULE_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      let scheduleDataList = resultData.data;
      this.scheduleModel.setData(scheduleDataList);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 스케쥴 등록
  postScheduleData(){
    const path = `${apiPath.SCHEDULE_LIST}`;
    const data = this.scheduleItemModel.getData();
    POST_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 등록되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 스케쥴 수정
  putScheduleData(){
    const path = `${apiPath.SCHEDULE_LIST}/${this.scheduleItemModel.id}`;
    const data = this.scheduleItemModel.getData();
    PUT_REQUEST(path,data).then(
    (success) => {
      const resultData = success.data;
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 수정되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  // 스케쥴 삭제
  deleteScheduleData(id){
    const path = `${apiPath.SCHEDULE_LIST}/${id}`;
    DELETE_REQUEST(path).then(
    (success) => {
      this.getScheduleData();
      this.onCloseSchedulePopup();
      store.dispatch('commonToast/fetchToastStart', '스케줄이 삭제되었습니다');
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
  getInfoData(){
    const query = makeQueryStringByObject(this.listModel.searchParams);
    const path = `${apiPath.SURVEY_INFO}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.listModel.setData(resultData);
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}