import { convertDateFormat, dateDiff, getweekDay, getWeeklyDateList } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class EventStatisticWeeklyModel {
  constructor(){
    this.targetDate = ''; // 주간 - 기준일 YYYY-MM-DD
    this.searchDateRangeWeekly = { start_date:'', end_date:'' }; // 주간 조회 일
    this.limit = { minDay:'', maxDay:'' };
    this.isDisaablePrev = false;
    this.isDisableNext = false;
    this.chartData = {
      labels:[],
      datasets:[
        {
          label: "email",
          backgroundColor: "blue",
          data: [],
        },
        {
          label: "phone",
          backgroundColor: "green",
          data: [],
        },
      ]
    }
  }
  setTargetDate(targetDate){
    this.targetDate = convertDateFormat(targetDate, 'YYYY-MM-DD');
    this.searchDateRangeWeekly.start_date = getweekDay(this.targetDate,'Sunday');
    this.searchDateRangeWeekly.end_date = getweekDay(this.targetDate,'Saturday');
  }
  setData(resultData){
    this.isDisaablePrev = dateDiff(this.limitMinDate, this.searchDateRangeWeekly.start_date) === 0;
    this.isDisableNext = dateDiff(new Date(), this.targetDate) === 0;
    const labelsDataList = getWeeklyDateList(this.targetDate);
    labelsDataList.forEach((label, labelIndex) => {
      const labelDate = label.date
      const data = resultData.find(item => item.date === labelDate);
      this.chartData.datasets[0].data[labelIndex] = data ? data.emailCount : 0;
      this.chartData.datasets[1].data[labelIndex] = data ? data.phoneCount : 0;
    });
    this.chartData.labels = labelsDataList.map(item => `${convertDateFormat(item.date, 'MM-DD')} (${item.weekday})`)
  }
}