export default class EventStatisticListModel {
  constructor(){
    this.dailyDataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }
  setData(obj){
    const { data, total, totalPage } = obj;
    this.dailyDataList = data;
    this.paginationData.totalCount = total;
    this.paginationData.totalPage = totalPage;
  }
}