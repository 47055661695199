import { convertDateFormat, dateDiff, calcDate, getweekDay, getDayToWeek, getMonthlyDateList, getMonthLastDate } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';

export default class EventStatisticMonthlyModel {
  constructor(){
    this.calendarElement = undefined;
    this.calendarData = {
      targetMonth: '', // 월간 - 기준월 YYYY-MM
      searchDataRangeMonthly: { start_date:'', end_date:'' }, // 월간 조회 일
      limit: { minMonth:'2021-10-01', maxMonth:'', isMaxMonthToday:true }, // 월간 - 기준 최소시작일
      isSchedule: true,
      isData: true,
      isMiniType: false
    };
    this.monthDataList = [];
  }
  setMonthDate(){
    this.calendarData.targetMonth = convertDateFormat(new Date(), 'YYYY-MM');
    // this.calendarData.searchDataRangeMonthly.start_date = `${this.calendarData.targetMonth}-01`;
    // this.calendarData.searchDataRangeMonthly.end_date = getMonthLastDate(this.calendarData.targetMonth);


    // this.calendarData.targetMonth = convertDateFormat(new Date(), 'YYYY-MM');
    // if(this.calendarElement){
    //   this.calendarElement.setMonthSearchDate();
    // }
    // if(this.calendarData.searchDataRangeMonthly.start_date && this.calendarData.searchDataRangeMonthly.end_date){
    //   this.getMonthData();
    // }
    // if(this.calendarElement){
    //   this.calendarElement.setMonthData();
    // }
  }
}